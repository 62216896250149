export const base = {
  colors: {
    primitives: {
      neutral: {
        '0': '#FFFFFF',
        '50': '#fafafa',
        '100': '#f4f4f5',
        '200': '#e4e4e7',
        '300': '#d4d4d8',
        '400': '#a1a1aa',
        '500': '#71717a',
        '600': '#52525b',
        '700': '#3f3f46',
        '800': '#27272a',
        '900': '#18181b',
        '950': '#09090b',
      },
      alphaWhite: {
        'alpha-88': 'rgba(255, 255, 255, 0.88)',
        'alpha-80': 'rgba(255, 255, 255, 0.80)',
        'alpha-72': 'rgba(255, 255, 255, 0.72)',
        'alpha-64': 'rgba(255, 255, 255, 0.64)',
        'alpha-56': 'rgba(255, 255, 255, 0.56)',
        'alpha-40': 'rgba(255, 255, 255, 0.40)',
        'alpha-24': 'rgba(255, 255, 255, 0.24)',
        'alpha-16': 'rgba(255, 255, 255, 0.16)',
        'alpha-12': 'rgba(255, 255, 255, 0.12)',
        'alpha-10': 'rgba(255, 255, 255, 0.10)',
        'alpha-8': 'rgba(255, 255, 255, 0.08)',
        'alpha-6': 'rgba(255, 255, 255, 0.06)',
        'alpha-4': 'rgba(255, 255, 255, 0.04)',
        'alpha-2': 'rgba(255, 255, 255, 0.02)',
        'alpha-0': 'rgba(255, 255, 255, 0.00)',
      },
      alphaBlack: {
        'alpha-88': 'rgba(9, 9, 11, 0.88)',
        'alpha-80': 'rgba(9, 9, 11, 0.80)',
        'alpha-72': 'rgba(9, 9, 11, 0.72)',
        'alpha-64': 'rgba(9, 9, 11, 0.64)',
        'alpha-56': 'rgba(9, 9, 11, 0.56)',
        'alpha-40': 'rgba(9, 9, 11, 0.40)',
        'alpha-24': 'rgba(9, 9, 11, 0.24)',
        'alpha-16': 'rgba(9, 9, 11, 0.16)',
        'alpha-12': 'rgba(9, 9, 11, 0.12)',
        'alpha-10': 'rgba(9, 9, 11, 0.10)',
        'alpha-8': 'rgba(9, 9, 11, 0.08)',
        'alpha-6': 'rgba(9, 9, 11, 0.06)',
        'alpha-4': 'rgba(9, 9, 11, 0.04)',
        'alpha-2': 'rgba(9, 9, 11, 0.02)',
        'alpha-0': 'rgba(9, 9, 11, 0.00)',
      },
      accent: {
        '50': '#f4f3ff',
        '100': '#ebe9fe',
        '200': '#d9d6fe',
        '300': '#bdb4fe',
        '400': '#9b8afb',
        '500': '#7a5af8',
        '600': '#6938ef',
        '700': '#5925dc',
        '800': '#4a1fb8',
        '900': '#3e1c96',
        '950': '#27115f',
      },
      green: {
        '50': '#ecfdf5',
        '100': '#d1fae5',
        '200': '#a7f3d0',
        '300': '#6ee7b7',
        '400': '#34d399',
        '500': '#10b981',
        '600': '#059669',
        '700': '#047857',
        '800': '#065f46',
        '900': '#064e3b',
        '950': '#022c22',
      },
      yellow: {
        '50': '#fff7ed',
        '100': '#ffedd5',
        '200': '#fed7aa',
        '300': '#fdba74',
        '400': '#fb923c',
        '500': '#f97316',
        '600': '#ea580c',
        '700': '#c2410c',
        '800': '#9a3412',
        '900': '#7c2d12',
        '950': '#431407',
      },
      red: {
        '50': '#fef3f2',
        '100': '#fee4e2',
        '200': '#fecdca',
        '300': '#fda29b',
        '400': '#f97066',
        '500': '#f04438',
        '600': '#d92d20',
        '700': '#b42318',
        '800': '#912018',
        '900': '#7a271a',
        '950': '#55160c',
      },
    },
  },
};
export const light = {
  backgroundPrimaryBgPrimary: base.colors.primitives.neutral['0'],
  backgroundPrimaryBgPrimaryInverse: base.colors.primitives.neutral['950'],
  backgroundPrimaryBgTransparent: base.colors.primitives.alphaWhite['alpha-0'],

  backgroundSecondaryBgSecondary: base.colors.primitives.neutral['50'],
  backgroundSecondaryBgSecondaryInverse: base.colors.primitives.neutral['900'],

  backgroundTertiaryBgTertiary: base.colors.primitives.neutral['100'],
  backgroundTertiaryBgTertiaryInverse: base.colors.primitives.neutral['800'],

  backgroundAccentBgAccent: base.colors.primitives.accent['600'],
  backgroundAccentBgAccentLight: base.colors.primitives.accent['100'],

  backgroundSuccessBgSuccess: base.colors.primitives.green['600'],
  backgroundSuccessBgSuccessLight: base.colors.primitives.green['100'],

  backgroundWarningBgWarning: base.colors.primitives.yellow['600'],
  backgroundWarningBgWarningLight: base.colors.primitives.yellow['100'],

  backgroundDangerBgDanger: base.colors.primitives.red['600'],
  backgroundDangerBgDangerLight: base.colors.primitives.red['100'],

  surfacePrimarySurfacePrimary: base.colors.primitives.neutral['0'],
  surfacePrimarySurfacePrimaryHover: base.colors.primitives.neutral['50'],
  surfacePrimarySurfacePrimaryPressed: base.colors.primitives.neutral['100'],
  surfacePrimarySurfacePrimaryDisabled: base.colors.primitives.neutral['100'],
  surfacePrimarySurfacePrimaryInverse: base.colors.primitives.neutral['950'],
  surfacePrimarySurfaceTransparent:
    base.colors.primitives.alphaWhite['alpha-0'],

  surfaceSecondarySurfaceSecondary: base.colors.primitives.neutral['50'],
  surfaceSecondarySurfaceSecondaryHover: base.colors.primitives.neutral['100'],
  surfaceSecondarySurfaceSecondaryPressed:
    base.colors.primitives.neutral['200'],
  surfaceSecondarySurfaceSecondaryDisabled:
    base.colors.primitives.neutral['50'],
  surfaceSecondarySurfacePrimaryInverse: base.colors.primitives.neutral['900'],

  surfaceTertiarySurfaceTertiary: base.colors.primitives.neutral['100'],
  surfaceTertiarySurfaceTertiaryHover: base.colors.primitives.neutral['200'],
  surfaceTertiarySurfaceTertiaryPressed: base.colors.primitives.neutral['300'],
  surfaceTertiarySurfaceTertiaryDisabled: base.colors.primitives.neutral['100'],

  surfaceAccentSurfaceAccent: base.colors.primitives.accent['600'],
  surfaceAccentSurfaceAccentHover: base.colors.primitives.accent['700'],
  surfaceAccentSurfaceAccentPressed: base.colors.primitives.accent['800'],
  surfaceAccentSurfaceAccentDisabled: base.colors.primitives.neutral['50'],
  surfaceAccentSurfaceAccentLight: base.colors.primitives.neutral['50'],
  surfaceAccentSurfaceAccentLightHover: base.colors.primitives.accent['100'],
  surfaceAccentSurfaceAccentLightPressed: base.colors.primitives.accent['200'],
  surfaceAccentSurfaceAccentLightDisabled: base.colors.primitives.neutral['50'],

  surfaceSuccessSurfaceSuccess: base.colors.primitives.green['600'],
  surfaceSuccessSurfaceSuccessHover: base.colors.primitives.green['700'],
  surfaceSuccessSurfaceSuccessPressed: base.colors.primitives.green['800'],
  surfaceSuccessSurfaceSuccessDisabled: base.colors.primitives.green['50'],
  surfaceSuccessSurfaceSuccessLight: base.colors.primitives.neutral['50'],
  surfaceSuccessSurfaceSuccessLightHover: base.colors.primitives.green['100'],
  surfaceSuccessSurfaceSuccessLightPressed: base.colors.primitives.green['200'],
  surfaceSuccessSurfaceSuccessLightDisabled:
    base.colors.primitives.neutral['50'],

  surfaceWarningSurfaceWarning: base.colors.primitives.yellow['600'],
  surfaceWarningSurfaceWarningHover: base.colors.primitives.yellow['700'],
  surfaceWarningSurfaceWarningPressed: base.colors.primitives.yellow['800'],
  surfaceWarningSurfaceWarningDisabled: base.colors.primitives.yellow['50'],
  surfaceWarningSurfaceWarningLight: base.colors.primitives.yellow['50'],
  surfaceWarningSurfaceWarningLightHover: base.colors.primitives.yellow['100'],
  surfaceWarningSurfaceWarningLightPressed:
    base.colors.primitives.yellow['200'],
  surfaceWarningSurfaceWarningLightDisabled:
    base.colors.primitives.neutral['50'],

  surfaceDangerSurfaceDanger: base.colors.primitives.red['600'],
  surfaceDangerSurfaceDangerHover: base.colors.primitives.red['700'],
  surfaceDangerSurfaceDangerPressed: base.colors.primitives.red['800'],
  surfaceDangerSurfaceDangerDisabled: base.colors.primitives.red['50'],
  surfaceDangerSurfaceDangerLight: base.colors.primitives.red['50'],
  surfaceDangerSurfaceDangerLightHover: base.colors.primitives.red['100'],
  surfaceDangerSurfaceDangerLightPressed: base.colors.primitives.red['200'],
  surfaceDangerSurfaceDangerLightDisabled: base.colors.primitives.neutral['50'],

  textPrimaryTextPrimary: base.colors.primitives.neutral['900'],
  textPrimaryTextPrimaryHover: base.colors.primitives.neutral['800'],
  textPrimaryTextPrimaryPressed: base.colors.primitives.neutral['700'],
  textPrimaryTextPrimaryDisabled: base.colors.primitives.neutral['300'],
  textPrimaryTextPrimaryMuted: base.colors.primitives.neutral['400'],
  textPrimaryTextPrimaryInverse: base.colors.primitives.neutral['0'],

  textSecondaryTextSecondary: base.colors.primitives.neutral['700'],
  textSecondaryTextSecondaryHover: base.colors.primitives.neutral['800'],
  textSecondaryTextSecondaryPressed: base.colors.primitives.neutral['900'],
  textSecondaryTextSecondaryDisabled: base.colors.primitives.neutral['500'],
  textSecondaryTextSecondaryMuted: base.colors.primitives.neutral['600'],
  textSecondaryTextSecondaryInverse: base.colors.primitives.neutral['300'],

  textTertiaryTextTertiary: base.colors.primitives.neutral['600'],
  textTertiaryTextTertiaryDisabled: base.colors.primitives.neutral['500'],
  textTertiaryTextTertiaryMuted: base.colors.primitives.neutral['500'],
  textTertiaryTextTertiaryInverse: base.colors.primitives.neutral['500'],

  textAccentTextAccent: base.colors.primitives.accent['600'],
  textAccentTextAccentHover: base.colors.primitives.accent['700'],
  textAccentTextAccentPressed: base.colors.primitives.accent['800'],
  textAccentTextAccentDisabled: base.colors.primitives.accent['400'],
  textAccentTextAccentStronger: base.colors.primitives.accent['800'],
  textAccentTextAccentStrongest: base.colors.primitives.accent['900'],

  textSuccessTextSuccess: base.colors.primitives.green['600'],
  textSuccessTextSuccessHover: base.colors.primitives.green['700'],
  textSuccessTextSuccessPressed: base.colors.primitives.green['800'],
  textSuccessTextSuccessDisabled: base.colors.primitives.green['400'],
  textSuccessTextSuccessMuted: base.colors.primitives.green['800'],
  textSuccessTextSecondaryInverse: base.colors.primitives.green['900'],

  textWarningTextWarning: base.colors.primitives.yellow['600'],
  textWarningTextWarningHover: base.colors.primitives.yellow['700'],
  textWarningTextWarningPressed: base.colors.primitives.yellow['800'],
  textWarningTextWarningDisabled: base.colors.primitives.yellow['400'],
  textWarningTextWarningMuted: base.colors.primitives.yellow['800'],
  textWarningTextSecondaryInverse: base.colors.primitives.yellow['900'],

  textDangerTextDanger: base.colors.primitives.red['600'],
  textDangerTextDangerHover: base.colors.primitives.red['700'],
  textDangerTextDangerPressed: base.colors.primitives.red['800'],
  textDangerTextDangerDisabled: base.colors.primitives.red['300'],
  textDangerTextDangerStronger: base.colors.primitives.red['800'],
  textDangerTextDangerStrongest: base.colors.primitives.red['900'],

  textWhiteOnColorTextWhiteOnColor: base.colors.primitives.neutral['0'],
  textWhiteOnColorTextWhiteOnColorHover: base.colors.primitives.neutral['0'],
  textWhiteOnColorTextWhiteOnColorPressed: base.colors.primitives.neutral['0'],
  textWhiteOnColorTextWhiteOnColorDisabled: base.colors.primitives.neutral['0'],

  textBlackOnColorTextBlackOnColor: base.colors.primitives.neutral['950'],
  textBlackOnColorTextBlackOnColorHover: base.colors.primitives.neutral['950'],
  textBlackOnColorTextBlackOnColorPressed:
    base.colors.primitives.neutral['950'],
  textBlackOnColorTextBlackOnColorDisabled:
    base.colors.primitives.neutral['400'],

  iconPrimaryIconPrimary: base.colors.primitives.neutral['900'],
  iconPrimaryIconPrimaryHover: base.colors.primitives.neutral['800'],
  iconPrimaryIconPrimaryPressed: base.colors.primitives.neutral['700'],
  iconPrimaryIconPrimaryDisabled: base.colors.primitives.neutral['300'],
  iconPrimaryIconPrimaryMuted: base.colors.primitives.neutral['400'],
  iconPrimaryIconPrimaryInverse: base.colors.primitives.neutral['0'],

  iconSecondaryIconSecondary: base.colors.primitives.neutral['700'],
  iconSecondaryIconSecondaryHover: base.colors.primitives.neutral['800'],
  iconSecondaryIconSecondaryPressed: base.colors.primitives.neutral['900'],
  iconSecondaryIconSecondaryDisabled: base.colors.primitives.neutral['500'],
  iconSecondaryIconSecondaryMuted: base.colors.primitives.neutral['600'],
  iconSecondaryIconSecondaryInverse: base.colors.primitives.neutral['300'],

  iconTertiaryIconTertiary: base.colors.primitives.neutral['600'],
  iconTertiaryIconTertiaryDisabled: base.colors.primitives.neutral['500'],
  iconTertiaryIconTertiaryMuted: base.colors.primitives.neutral['600'],
  iconTertiaryIconTertiaryInverse: base.colors.primitives.neutral['500'],

  iconAccentIconAccent: base.colors.primitives.accent['600'],
  iconAccentIconAccentHover: base.colors.primitives.accent['700'],
  iconAccentIconAccentPressed: base.colors.primitives.accent['800'],
  iconAccentIconAccentDisabled: base.colors.primitives.accent['300'],
  iconAccentIconAccentStronger: base.colors.primitives.accent['800'],
  iconAccentIconAccentStrongest: base.colors.primitives.accent['900'],

  iconSuccessIconSuccess: base.colors.primitives.green['600'],
  iconSuccessIconSuccessHover: base.colors.primitives.green['700'],
  iconSuccessIconSuccessPressed: base.colors.primitives.green['800'],
  iconSuccessIconSuccessDisabled: base.colors.primitives.green['300'],
  iconSuccessIconSuccessStronger: base.colors.primitives.green['800'],
  iconSuccessSecondaryIconStrongest: base.colors.primitives.green['900'],

  iconWarningIconWarning: base.colors.primitives.yellow['600'],
  iconWarningIconWarningHover: base.colors.primitives.yellow['700'],
  iconWarningIconWarningPressed: base.colors.primitives.yellow['800'],
  iconWarningIconWarningDisabled: base.colors.primitives.yellow['300'],
  iconWarningIconWarningStronger: base.colors.primitives.yellow['800'],
  iconWarningSecondaryIconStrongest: base.colors.primitives.yellow['900'],

  iconDangerIconDanger: base.colors.primitives.red['600'],
  iconDangerIconDangerHover: base.colors.primitives.red['700'],
  iconDangerIconDangerPressed: base.colors.primitives.red['800'],
  iconDangerIconDangerDisabled: base.colors.primitives.red['300'],
  iconDangerIconDangerStronger: base.colors.primitives.red['800'],
  iconDangerIconDangerStrongest: base.colors.primitives.red['900'],

  iconWhiteOnColorIconWhiteOnColor: base.colors.primitives.neutral['0'],
  iconWhiteOnColorIconWhiteOnColorHover: base.colors.primitives.neutral['0'],
  iconWhiteOnColorIconWhiteOnColorPressed: base.colors.primitives.neutral['0'],
  iconWhiteOnColorIconWhiteOnColorDisabled: base.colors.primitives.neutral['0'],

  iconBlackOnColorIconBlackOnColor: base.colors.primitives.neutral['950'],
  iconBlackOnColorIconBlackOnColorHover: base.colors.primitives.neutral['950'],
  iconBlackOnColorIconBlackOnColorPressed:
    base.colors.primitives.neutral['950'],
  iconBlackOnColorIconBlackOnColorDisabled:
    base.colors.primitives.neutral['400'],

  borderPrimaryBorderPrimary: base.colors.primitives.neutral['300'],
  borderPrimaryBorderPrimaryHover: base.colors.primitives.neutral['400'],
  borderPrimaryBorderPrimaryPressed: base.colors.primitives.neutral['500'],
  borderPrimaryBorderPrimaryDisabled: base.colors.primitives.neutral['200'],

  borderSecondaryBorderSecondary: base.colors.primitives.neutral['200'],
  borderSecondaryBorderSecondaryHover: base.colors.primitives.neutral['300'],
  borderSecondaryBorderSecondaryPressed: base.colors.primitives.neutral['400'],
  borderSecondaryBorderSecondaryDisabled: base.colors.primitives.neutral['100'],

  borderAccentBorderAccent: base.colors.primitives.accent['200'],
  borderAccentBorderAccentHover: base.colors.primitives.accent['300'],
  borderAccentBorderAccentPressed: base.colors.primitives.accent['400'],
  borderAccentBorderAccentDisabled: base.colors.primitives.accent['50'],
  borderAccentBorderAccentStronger: base.colors.primitives.accent['600'],

  borderSuccessBorderSuccess: base.colors.primitives.green['200'],
  borderSuccessBorderSuccessHover: base.colors.primitives.green['300'],
  borderAccentBorderPressed: base.colors.primitives.green['400'],
  borderAccentBorderDisabled: base.colors.primitives.green['50'],
  borderSuccessBorderStronger: base.colors.primitives.green['600'],

  borderWarningBorderWarning: base.colors.primitives.yellow['200'],
  borderWarningBorderWarningHover: base.colors.primitives.yellow['300'],
  borderWarningBorderWarningPressed: base.colors.primitives.yellow['400'],
  borderWarningBorderWarningDisabled: base.colors.primitives.yellow['50'],
  borderWarningBorderStronger: base.colors.primitives.yellow['600'],

  borderDangerBorderDanger: base.colors.primitives.red['200'],
  borderDangerBorderDangerHover: base.colors.primitives.red['300'],
  borderDangerBorderDangerPressed: base.colors.primitives.red['400'],
  borderDangerBorderDangerDisabled: base.colors.primitives.red['50'],
  borderDangerBorderStronger: base.colors.primitives.red['600'],

  borderWhiteOnColorBorderWhiteOnColor: base.colors.primitives.neutral['0'],
  borderWhiteOnColorBorderWhiteOnColorHover:
    base.colors.primitives.neutral['0'],
  borderWhiteOnColorBorderWhiteOnColorPressed:
    base.colors.primitives.neutral['0'],
  borderWhiteOnColorBorderWhiteOnColorDisabled:
    base.colors.primitives.neutral['0'],

  borderStrongestBorderStrongest: base.colors.primitives.neutral['950'],

  btnPrimaryBg: base.colors.primitives.accent['600'],
  btnPrimaryFg: base.colors.primitives.neutral['0'],
  btnPrimaryBorder: base.colors.primitives.neutral['0'],
};
export const dark = {
  backgroundPrimaryBgPrimary: base.colors.primitives.neutral['0'],
  backgroundPrimaryBgPrimaryInverse: base.colors.primitives.neutral['950'],
  backgroundPrimaryBgTransparent: base.colors.primitives.alphaWhite['alpha-0'],

  backgroundSecondaryBgSecondary: base.colors.primitives.neutral['50'],
  backgroundSecondaryBgSecondaryInverse: base.colors.primitives.neutral['900'],

  backgroundTertiaryBgTertiary: base.colors.primitives.neutral['100'],
  backgroundTertiaryBgTertiaryInverse: base.colors.primitives.neutral['800'],

  backgroundAccentBgAccent: base.colors.primitives.accent['600'],
  backgroundAccentBgAccentLight: base.colors.primitives.accent['100'],

  backgroundSuccessBgSuccess: base.colors.primitives.green['600'],
  backgroundSuccessBgSuccessLight: base.colors.primitives.green['100'],

  backgroundWarningBgWarning: base.colors.primitives.yellow['600'],
  backgroundWarningBgWarningLight: base.colors.primitives.yellow['100'],

  backgroundDangerBgDanger: base.colors.primitives.red['600'],
  backgroundDangerBgDangerLight: base.colors.primitives.red['100'],

  surfacePrimarySurfacePrimary: base.colors.primitives.neutral['0'],
  surfacePrimarySurfacePrimaryHover: base.colors.primitives.neutral['50'],
  surfacePrimarySurfacePrimaryPressed: base.colors.primitives.neutral['100'],
  surfacePrimarySurfacePrimaryDisabled: base.colors.primitives.neutral['100'],
  surfacePrimarySurfacePrimaryInverse: base.colors.primitives.neutral['950'],
  surfacePrimarySurfaceTransparent:
    base.colors.primitives.alphaWhite['alpha-0'],

  surfaceSecondarySurfaceSecondary: base.colors.primitives.neutral['50'],
  surfaceSecondarySurfaceSecondaryHover: base.colors.primitives.neutral['100'],
  surfaceSecondarySurfaceSecondaryPressed:
    base.colors.primitives.neutral['200'],
  surfaceSecondarySurfaceSecondaryDisabled:
    base.colors.primitives.neutral['50'],
  surfaceSecondarySurfacePrimaryInverse: base.colors.primitives.neutral['900'],

  surfaceTertiarySurfaceTertiary: base.colors.primitives.neutral['100'],
  surfaceTertiarySurfaceTertiaryHover: base.colors.primitives.neutral['200'],
  surfaceTertiarySurfaceTertiaryPressed: base.colors.primitives.neutral['300'],
  surfaceTertiarySurfaceTertiaryDisabled: base.colors.primitives.neutral['100'],

  surfaceAccentSurfaceAccent: base.colors.primitives.accent['600'],
  surfaceAccentSurfaceAccentHover: base.colors.primitives.accent['700'],
  surfaceAccentSurfaceAccentPressed: base.colors.primitives.accent['800'],
  surfaceAccentSurfaceAccentDisabled: base.colors.primitives.neutral['50'],
  surfaceAccentSurfaceAccentLight: base.colors.primitives.neutral['50'],
  surfaceAccentSurfaceAccentLightHover: base.colors.primitives.accent['100'],
  surfaceAccentSurfaceAccentLightPressed: base.colors.primitives.accent['200'],
  surfaceAccentSurfaceAccentLightDisabled: base.colors.primitives.neutral['50'],

  surfaceSuccessSurfaceSuccess: base.colors.primitives.green['600'],
  surfaceSuccessSurfaceSuccessHover: base.colors.primitives.green['700'],
  surfaceSuccessSurfaceSuccessPressed: base.colors.primitives.green['800'],
  surfaceSuccessSurfaceSuccessDisabled: base.colors.primitives.green['50'],
  surfaceSuccessSurfaceSuccessLight: base.colors.primitives.neutral['50'],
  surfaceSuccessSurfaceSuccessLightHover: base.colors.primitives.green['100'],
  surfaceSuccessSurfaceSuccessLightPressed: base.colors.primitives.green['200'],
  surfaceSuccessSurfaceSuccessLightDisabled:
    base.colors.primitives.neutral['50'],

  surfaceWarningSurfaceWarning: base.colors.primitives.yellow['600'],
  surfaceWarningSurfaceWarningHover: base.colors.primitives.yellow['700'],
  surfaceWarningSurfaceWarningPressed: base.colors.primitives.yellow['800'],
  surfaceWarningSurfaceWarningDisabled: base.colors.primitives.yellow['50'],
  surfaceWarningSurfaceWarningLight: base.colors.primitives.yellow['50'],
  surfaceWarningSurfaceWarningLightHover: base.colors.primitives.yellow['100'],
  surfaceWarningSurfaceWarningLightPressed:
    base.colors.primitives.yellow['200'],
  surfaceWarningSurfaceWarningLightDisabled:
    base.colors.primitives.neutral['50'],

  surfaceDangerSurfaceDanger: base.colors.primitives.red['600'],
  surfaceDangerSurfaceDangerHover: base.colors.primitives.red['700'],
  surfaceDangerSurfaceDangerPressed: base.colors.primitives.red['800'],
  surfaceDangerSurfaceDangerDisabled: base.colors.primitives.red['50'],
  surfaceDangerSurfaceDangerLight: base.colors.primitives.red['50'],
  surfaceDangerSurfaceDangerLightHover: base.colors.primitives.red['100'],
  surfaceDangerSurfaceDangerLightPressed: base.colors.primitives.red['200'],
  surfaceDangerSurfaceDangerLightDisabled: base.colors.primitives.neutral['50'],

  textPrimaryTextPrimary: base.colors.primitives.neutral['900'],
  textPrimaryTextPrimaryHover: base.colors.primitives.neutral['800'],
  textPrimaryTextPrimaryPressed: base.colors.primitives.neutral['700'],
  textPrimaryTextPrimaryDisabled: base.colors.primitives.neutral['300'],
  textPrimaryTextPrimaryMuted: base.colors.primitives.neutral['400'],
  textPrimaryTextPrimaryInverse: base.colors.primitives.neutral['0'],

  textSecondaryTextSecondary: base.colors.primitives.neutral['700'],
  textSecondaryTextSecondaryHover: base.colors.primitives.neutral['800'],
  textSecondaryTextSecondaryPressed: base.colors.primitives.neutral['900'],
  textSecondaryTextSecondaryDisabled: base.colors.primitives.neutral['500'],
  textSecondaryTextSecondaryMuted: base.colors.primitives.neutral['600'],
  textSecondaryTextSecondaryInverse: base.colors.primitives.neutral['300'],

  textTertiaryTextTertiary: base.colors.primitives.neutral['600'],
  textTertiaryTextTertiaryDisabled: base.colors.primitives.neutral['500'],
  textTertiaryTextTertiaryMuted: base.colors.primitives.neutral['500'],
  textTertiaryTextTertiaryInverse: base.colors.primitives.neutral['500'],

  textAccentTextAccent: base.colors.primitives.accent['600'],
  textAccentTextAccentHover: base.colors.primitives.accent['700'],
  textAccentTextAccentPressed: base.colors.primitives.accent['800'],
  textAccentTextAccentDisabled: base.colors.primitives.accent['400'],
  textAccentTextAccentStronger: base.colors.primitives.accent['800'],
  textAccentTextAccentStrongest: base.colors.primitives.accent['900'],

  textSuccessTextSuccess: base.colors.primitives.green['600'],
  textSuccessTextSuccessHover: base.colors.primitives.green['700'],
  textSuccessTextSuccessPressed: base.colors.primitives.green['800'],
  textSuccessTextSuccessDisabled: base.colors.primitives.green['400'],
  textSuccessTextSuccessMuted: base.colors.primitives.green['800'],
  textSuccessTextSecondaryInverse: base.colors.primitives.green['900'],

  textWarningTextWarning: base.colors.primitives.yellow['600'],
  textWarningTextWarningHover: base.colors.primitives.yellow['700'],
  textWarningTextWarningPressed: base.colors.primitives.yellow['800'],
  textWarningTextWarningDisabled: base.colors.primitives.yellow['400'],
  textWarningTextWarningMuted: base.colors.primitives.yellow['800'],
  textWarningTextSecondaryInverse: base.colors.primitives.yellow['900'],

  textDangerTextDanger: base.colors.primitives.red['600'],
  textDangerTextDangerHover: base.colors.primitives.red['700'],
  textDangerTextDangerPressed: base.colors.primitives.red['800'],
  textDangerTextDangerDisabled: base.colors.primitives.red['300'],
  textDangerTextDangerStronger: base.colors.primitives.red['800'],
  textDangerTextDangerStrongest: base.colors.primitives.red['900'],

  textWhiteOnColorTextWhiteOnColor: base.colors.primitives.neutral['0'],
  textWhiteOnColorTextWhiteOnColorHover: base.colors.primitives.neutral['0'],
  textWhiteOnColorTextWhiteOnColorPressed: base.colors.primitives.neutral['0'],
  textWhiteOnColorTextWhiteOnColorDisabled: base.colors.primitives.neutral['0'],

  textBlackOnColorTextBlackOnColor: base.colors.primitives.neutral['950'],
  textBlackOnColorTextBlackOnColorHover: base.colors.primitives.neutral['950'],
  textBlackOnColorTextBlackOnColorPressed:
    base.colors.primitives.neutral['950'],
  textBlackOnColorTextBlackOnColorDisabled:
    base.colors.primitives.neutral['400'],

  iconPrimaryIconPrimary: base.colors.primitives.neutral['900'],
  iconPrimaryIconPrimaryHover: base.colors.primitives.neutral['800'],
  iconPrimaryIconPrimaryPressed: base.colors.primitives.neutral['700'],
  iconPrimaryIconPrimaryDisabled: base.colors.primitives.neutral['300'],
  iconPrimaryIconPrimaryMuted: base.colors.primitives.neutral['400'],
  iconPrimaryIconPrimaryInverse: base.colors.primitives.neutral['0'],

  iconSecondaryIconSecondary: base.colors.primitives.neutral['700'],
  iconSecondaryIconSecondaryHover: base.colors.primitives.neutral['800'],
  iconSecondaryIconSecondaryPressed: base.colors.primitives.neutral['900'],
  iconSecondaryIconSecondaryDisabled: base.colors.primitives.neutral['500'],
  iconSecondaryIconSecondaryMuted: base.colors.primitives.neutral['600'],
  iconSecondaryIconSecondaryInverse: base.colors.primitives.neutral['300'],

  iconTertiaryIconTertiary: base.colors.primitives.neutral['600'],
  iconTertiaryIconTertiaryDisabled: base.colors.primitives.neutral['500'],
  iconTertiaryIconTertiaryMuted: base.colors.primitives.neutral['600'],
  iconTertiaryIconTertiaryInverse: base.colors.primitives.neutral['500'],

  iconAccentIconAccent: base.colors.primitives.accent['600'],
  iconAccentIconAccentHover: base.colors.primitives.accent['700'],
  iconAccentIconAccentPressed: base.colors.primitives.accent['800'],
  iconAccentIconAccentDisabled: base.colors.primitives.accent['300'],
  iconAccentIconAccentStronger: base.colors.primitives.accent['800'],
  iconAccentIconAccentStrongest: base.colors.primitives.accent['900'],

  iconSuccessIconSuccess: base.colors.primitives.green['600'],
  iconSuccessIconSuccessHover: base.colors.primitives.green['700'],
  iconSuccessIconSuccessPressed: base.colors.primitives.green['800'],
  iconSuccessIconSuccessDisabled: base.colors.primitives.green['300'],
  iconSuccessIconSuccessStronger: base.colors.primitives.green['800'],
  iconSuccessSecondaryIconStrongest: base.colors.primitives.green['900'],

  iconWarningIconWarning: base.colors.primitives.yellow['600'],
  iconWarningIconWarningHover: base.colors.primitives.yellow['700'],
  iconWarningIconWarningPressed: base.colors.primitives.yellow['800'],
  iconWarningIconWarningDisabled: base.colors.primitives.yellow['300'],
  iconWarningIconWarningStronger: base.colors.primitives.yellow['800'],
  iconWarningSecondaryIconStrongest: base.colors.primitives.yellow['900'],

  iconDangerIconDanger: base.colors.primitives.red['600'],
  iconDangerIconDangerHover: base.colors.primitives.red['700'],
  iconDangerIconDangerPressed: base.colors.primitives.red['800'],
  iconDangerIconDangerDisabled: base.colors.primitives.red['300'],
  iconDangerIconDangerStronger: base.colors.primitives.red['800'],
  iconDangerIconDangerStrongest: base.colors.primitives.red['900'],

  iconWhiteOnColorIconWhiteOnColor: base.colors.primitives.neutral['0'],
  iconWhiteOnColorIconWhiteOnColorHover: base.colors.primitives.neutral['0'],
  iconWhiteOnColorIconWhiteOnColorPressed: base.colors.primitives.neutral['0'],
  iconWhiteOnColorIconWhiteOnColorDisabled: base.colors.primitives.neutral['0'],

  iconBlackOnColorIconBlackOnColor: base.colors.primitives.neutral['950'],
  iconBlackOnColorIconBlackOnColorHover: base.colors.primitives.neutral['950'],
  iconBlackOnColorIconBlackOnColorPressed:
    base.colors.primitives.neutral['950'],
  iconBlackOnColorIconBlackOnColorDisabled:
    base.colors.primitives.neutral['400'],

  borderPrimaryBorderPrimary: base.colors.primitives.neutral['300'],
  borderPrimaryBorderPrimaryHover: base.colors.primitives.neutral['400'],
  borderPrimaryBorderPrimaryPressed: base.colors.primitives.neutral['500'],
  borderPrimaryBorderPrimaryDisabled: base.colors.primitives.neutral['200'],

  borderSecondaryBorderSecondary: base.colors.primitives.neutral['200'],
  borderSecondaryBorderSecondaryHover: base.colors.primitives.neutral['300'],
  borderSecondaryBorderSecondaryPressed: base.colors.primitives.neutral['400'],
  borderSecondaryBorderSecondaryDisabled: base.colors.primitives.neutral['100'],

  borderAccentBorderAccent: base.colors.primitives.accent['200'],
  borderAccentBorderAccentHover: base.colors.primitives.accent['300'],
  borderAccentBorderAccentPressed: base.colors.primitives.accent['400'],
  borderAccentBorderAccentDisabled: base.colors.primitives.accent['50'],
  borderAccentBorderAccentStronger: base.colors.primitives.accent['600'],

  borderSuccessBorderSuccess: base.colors.primitives.green['200'],
  borderSuccessBorderSuccessHover: base.colors.primitives.green['300'],
  borderAccentBorderPressed: base.colors.primitives.green['400'],
  borderAccentBorderDisabled: base.colors.primitives.green['50'],
  borderSuccessBorderStronger: base.colors.primitives.green['600'],

  borderWarningBorderWarning: base.colors.primitives.yellow['200'],
  borderWarningBorderWarningHover: base.colors.primitives.yellow['300'],
  borderWarningBorderWarningPressed: base.colors.primitives.yellow['400'],
  borderWarningBorderWarningDisabled: base.colors.primitives.yellow['50'],
  borderWarningBorderStronger: base.colors.primitives.yellow['600'],

  borderDangerBorderDanger: base.colors.primitives.red['200'],
  borderDangerBorderDangerHover: base.colors.primitives.red['300'],
  borderDangerBorderDangerPressed: base.colors.primitives.red['400'],
  borderDangerBorderDangerDisabled: base.colors.primitives.red['50'],
  borderDangerBorderStronger: base.colors.primitives.red['600'],

  borderWhiteOnColorBorderWhiteOnColor: base.colors.primitives.neutral['0'],
  borderWhiteOnColorBorderWhiteOnColorHover:
    base.colors.primitives.neutral['0'],
  borderWhiteOnColorBorderWhiteOnColorPressed:
    base.colors.primitives.neutral['0'],
  borderWhiteOnColorBorderWhiteOnColorDisabled:
    base.colors.primitives.neutral['0'],

  borderStrongestBorderStrongest: base.colors.primitives.neutral['950'],

  btnPrimaryBg: base.colors.primitives.accent['600'],
  btnPrimaryFg: base.colors.primitives.neutral['0'],
  btnPrimaryBorder: base.colors.primitives.neutral['0'],
};

const theme = {
  newColors: light,
  colors: {
    dark: {
      light: '#FCFCFC',
    },
    headerGradient:
      'linear-gradient(90deg, rgba(24, 19, 38, 1) 0%,rgba(105, 56, 239, 1) 100%)',
    primary: {
      '25': '#e6fef8',
      '50': '#ccfdf0',
      '100': '#B3fce9',
      '200': '#99fbe1',
      '300': '#80fada',
      '400': '#67f9d2',
      '500': '#4df8cb',
      '600': '#01f5b4',
      '700': '#01dda2',
      '800': '#01c490',
      '900': '#01ac7e',
      '950': '#01936c',
    },
    secondary: {
      '25': '#f5fce9',
      '50': '#e2f6bd',
      '100': '#d8f3a7',
      '200': '#cff092',
      '300': '#c5ec7c',
      '400': '#bbe966',
      '500': '#b1e650',
      '600': '#9ee024',
      '700': '#8eca20',
      '800': '#6f9d19',
      '900': '#5f8616',
      '950': '#223008',
    },
    accent: {
      '25': '#e9eaf8',
      '50': '#d2d4f1',
      '100': '#bcbfea',
      '200': '#a5a9e3',
      '300': '#8f94dc',
      '400': '#6269cd',
      '500': '#4c53c6',
      '600': '#1f28b8',
      '700': '#1c24a6',
      '800': '#192093',
      '900': '#161c81',
      '950': '#13186e',
    },
    gray: {
      '25': '#fcfcfd',
      '50': '#f9fafb',
      '100': '#f2f4f7',
      '200': '#eaecf0',
      '300': '#d0d5dd',
      '400': '#98a2b3',
      '500': '#667085',
      '600': '#475467',
      '700': '#344054',
      '800': '#182230',
      '900': '#101828',
      '950': '#0c111d',
    },
    success: {
      '25': '#f6fef9',
      '50': '#ecfdf3',
      '100': '#dcfae6',
      '200': '#abefc6',
      '300': '#75e0a7',
      '400': '#47cd89',
      '500': '#17b26a',
      '600': '#079455',
      '700': '#067647',
      '800': '#085d3a',
      '900': '#074d31',
      '950': '#053321',
    },
    error: {
      '25': '#fffbfa',
      '50': '#fef3f2',
      '100': '#fee4e2',
      '200': '#fecdca',
      '300': '#fda29b',
      '400': '#f97066',
      '500': '#f04438',
      '600': '#d92d20',
      '700': '#b42318',
      '800': '#912018',
      '900': '#7a271a',
      '950': '#55160c',
    },
    warning: {
      '25': '#fffcf5',
      '50': '#fffaeb',
      '100': '#fef0c7',
      '200': '#fedf89',
      '300': '#fec84b',
      '400': '#fdb022',
      '500': '#f79009',
      '600': '#dc6803',
      '700': '#b54708',
      '800': '#93370d',
      '900': '#7a2e0e',
      '950': '#4e1d09',
    },
    utilityBlue: {
      '50': '#EFF8FF',
      '400': '#53B1FD',
      '700': '#175CD3',
    },
    white: '#FFFFFF',
    black: '#000000',
  },
  typography: {
    fontFamily: {
      inter: ['var(--font-inter)'],
    },
    fontWeight: {
      regular: 400,
      medium: 500,
      semiBold: 600,
      bold: 700,
    },
    fontSize: {
      '12': {
        size: '12px',
        lineHeight: '16px',
      },
      '14': {
        size: '14px',
        lineHeight: '20px',
      },
      '16': {
        size: '16px',
        lineHeight: '24px',
      },
      '18': {
        size: '18px',
        lineHeight: '28px',
      },
      '20': {
        size: '20px',
        lineHeight: '30px',
      },
      '24': {
        size: '24px',
        lineHeight: '32px',
      },
      '30': {
        size: '30px',
        lineHeight: '38px',
      },
      '36': {
        size: '36px',
        lineHeight: '44px',
      },
      '48': {
        size: '48px',
        lineHeight: '60px',
      },
      '60': {
        size: '60px',
        lineHeight: '72px',
      },
    },
  },
  shadows: {
    xs: '0px 1px 2px 0px rgba(16, 24, 40, 0.05)',
    sm: '0px 1px 3px 0px rgba(16, 24, 40, 0.10)',
    md: '0px 4px 8px -2px rgba(16, 24, 40, 0.10)',
    lg: '0px 12px 16px -4px rgba(16, 24, 40, 0.08)',
    xl: '0px 20px 24px -4px rgba(16, 24, 40, 0.08)',
    '2xl': '0px 24px 48px -12px rgba(16, 24, 40, 0.18)',
    '3xl': '0px 32px 64px -12px rgba(16, 24, 40, 0.14)',
  },
  radius: {
    xxxs: '4px',
    xxs: '6px',
    xs: '8px',
    sm: '12px',
    md: '16px',
    lg: '20px',
    xl: '28px',
    pill: '200px',
    circle: '50%',
  },
  spacing: {
    '02': '2px',
    '04': '4px',
    '06': '6px',
    '08': '8px',
    '10': '10px',
    '12': '12px',
    '14': '14px',
    '16': '16px',
    '20': '20px',
    '24': '24px',
    '32': '32px',
    '40': '40px',
    '48': '48px',
    '64': '64px',
    '80': '80px',
    '96': '96px',
    '100': '100px',
  },
  breakpoints: {
    mobile320: '@media (min-width: 320px)',
    mobile360: '@media (min-width: 360px)',
    mobile540: '@media (min-width: 540px)',
    tablet720: '@media (min-width: 720px)',
    tablet960: '@media (min-width: 960px)',
    laptop: '@media (min-width: 1024px)',
    desktop: '@media (min-width: 1200px)',
  },
};

export default theme;
